import type { I18nMessage } from "@pollen/core/model/i18n.model";

export function useOfferList() {
  const list = [
    {
      id: "talent",
      title: "Talent",
      subtitle: "Invitez vos talents à nos formations inter-entreprises",
      features: [
        "Français",
        "Présentiel à Paris",
        "Max 12 participants par session",
      ],
      quote_based_pricing: false,
      enrolled_customers: [
        {
          logo: "https://res.cloudinary.com/dfdgwwkpd/image/upload/v1727254804/company-logos/color/png/Group-2_zphsjh.png",
          name: "Malt",
        },
        {
          logo: "https://res.cloudinary.com/dfdgwwkpd/image/upload/v1728894139/company-logos/color/png/Capture_d_e%CC%81cran_2024-10-14_a%CC%80_10.22.08_ddqpea.png",
          name: "Orange",
        },
      ],
      classes: "bg-white",
    },
    {
      id: "team",
      title: "Team",
      subtitle: "Privatisez vos formations intra-entreprise pour vos équipes",
      features: [
        "Français ou anglais",
        "En ligne ou présentiel",
        "Programme personnalisable",
      ],
      quote_based_pricing: true,
      enrolled_customers: [
        {
          logo: "https://res.cloudinary.com/dfdgwwkpd/image/upload/v1728894322/company-logos/color/png/logo-bnp_1_qbf7wi.png",
          name: "BNPP",
        },
        {
          logo: "https://res.cloudinary.com/dfdgwwkpd/image/upload/v1727254751/company-logos/color/png/doctolib_ufrxyb.png",
          name: "Doctolib",
        },
      ],
      classes: "bg-primary-50 text-primary-950",
    },
    {
      id: "academy",
      title: "Academy",
      subtitle:
        "Créez votre programme de formation sur mesure pour vos équipes",
      features: [
        "Français ou anglais",
        "En ligne ou présentiel",
        "Learning designer dédié",
        "Masterclass, formations, et autres formats",
        "Coaching de vos experts internes (option)",
      ],
      quote_based_pricing: true,
      enrolled_customers: [
        {
          logo: "https://res.cloudinary.com/dfdgwwkpd/image/upload/v1727258747/company-logos/color/png/loreal_qonvx8.svg",
          name: "L'Oréal",
        },
        {
          logo: "https://res.cloudinary.com/dfdgwwkpd/image/upload/v1728894516/company-logos/color/png/Frame_llkpdg.png",
          name: "Deezer",
        },
      ],
      classes: "bg-primary-900 text-white",
    },
  ] as const satisfies {
    id: string;
    title: I18nMessage;
    subtitle: I18nMessage;
    features: I18nMessage[];
    quote_based_pricing: boolean;
    enrolled_customers: { logo: string; name: string }[];
    classes: string;
  }[];

  function getOfferById(id: (typeof list)[number]["id"]) {
    return list.find((offer) => offer.id === id);
  }

  return {
    list,
    getOfferById,
  };
}

export type OfferCardDefinition = ReturnType<
  typeof useOfferList
>["list"][number];
